import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "../components/link"


const NotFoundPage = ({ data }) => (
  <Layout data={data}>
    <SEO page={{ titolo: "404: Not found" }} data={data} />
    <div className="container">
      <div className="row">
        <div className="col-12 py-5 text-center">
          <h1>404 - PAGINA NON DISPONIBILE</h1>
          <h2>
            La pagina su cui sei atterrato non è disponibile.
          </h2>
          <p>
            <Link to="/"><strong>Torna alla homepage</strong></Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage;
export const query = graphql`{
  ...DefaultSeo
  ...HeaderFragment
  ...FooterRootFragment
}`
